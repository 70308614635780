<template>
  <YoTable
    :api-endpoint="apiEndpoint"
    :title-tbl="titleTbl"
    :sub-title-tbl="subTitleTbl"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    bottom-label="Keterangan: Data ini Digunakan Pada ~Form Land Vehicle~"
    bottom-icon="GitMergeIcon"
    info-page-ability="Manage (All / Agen All / Agen)."
    placeholder-search="Nama Agen"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/CompTableT1T2.vue'

export default {
  components: {
    YoTable,
  },
  data() {
    return {
      apiEndpoint: 'group',
      titleTbl: this.$t('COMPANY OWN'),
      subTitleTbl: this.$t('Agen'),
      fields: [
        {
          key: 'name',
          label: 'Name Group',
          placeholder: 'Name Group',
          sortable: true,
          input: { type: 'input-noarea' },
        },
        {
          key: 'description',
          label: 'Full Name Group',
          placeholder: 'Description',
          sortable: true,
          input: { type: 'input-noarea' },
        },

      ],
    }
  },
  computed: {
    newDataTemplate() {
      return {
        name: '',
        description: '',
      }
    },
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.groups
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC',
        },
      }
    },
  },
}
</script>
