var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('YoTable', {
    attrs: {
      "api-endpoint": _vm.apiEndpoint,
      "title-tbl": _vm.titleTbl,
      "sub-title-tbl": _vm.subTitleTbl,
      "fields": _vm.fields,
      "new-data-template": _vm.newDataTemplate,
      "resolve-fetch-response": _vm.resolveFetchResponse,
      "resolve-fetch-url": _vm.resolveFetchUrl,
      "bottom-label": "Keterangan: Data ini Digunakan Pada ~Form Land Vehicle~",
      "bottom-icon": "GitMergeIcon",
      "info-page-ability": "Manage (All / Agen All / Agen).",
      "placeholder-search": "Nama Agen"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }